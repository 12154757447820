import React from 'react';
import { Link } from 'react-router-dom';
import './nav.css';

const Nav: React.FC = () => {
  return (
    <nav className="App-nav">
      <ul className="nav-list">
        <li className="nav-item"><Link to='/'>About Us</Link></li>
        <li className="nav-item"><Link to='/services'>Services</Link></li>
        <li className="nav-item"><Link to='/contact'>Contact</Link></li>
      </ul>
    </nav>
  );
}

export default Nav;

