import React from 'react';
import { Route, Routes, Link } from 'react-router-dom';
import Header from './components/header/header';
import Footer from './components/footer/footer';
import HomePage from './pages/homepage';
import ContactPage from './pages/contactpage';
import AboutUsPage from './pages/aboutuspage';
import ServicePage from './pages/servicepage';
import PrivacyPolicyPage from './pages/privacypolicypage';
import CookieConsent from 'react-cookie-consent';
import './App.css';

const App: React.FC = () => {
  return (
    <div className="App">
      <Header />
      <div className="App-main">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/about" element={<AboutUsPage />} /> 
          <Route path="/services" element={<ServicePage />} /> 
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        </Routes>
      </div>
      <Footer />
      <CookieConsent
          location="bottom"
          buttonText="I understand"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          expires={150}
        >
          This website uses cookies to enhance the user experience.{" "}
        <Link to="/privacy-policy" style={{ color: "#61dafb" }}>
          Learn more
        </Link>
      </CookieConsent>
    </div>
  );
}

export default App;
