import React from 'react';
import { Link } from 'react-router-dom';
import Nav from '../nav/nav';
import './header.css';

const Header: React.FC = () => {
    return (
      <header className="App-header">
        <Link to="/" className="App-logo">
          <h1>DATA-TRAP</h1>
        </Link>
        <Nav />
      </header>
    );
  }

export default Header;
