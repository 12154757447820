import React from 'react';

const AboutUsPage: React.FC = () => {
  return (
    <main>
      <section id="aboutus">
        <p>We are ・・・</p>
      </section>
    </main>
  );
}

export default AboutUsPage;
