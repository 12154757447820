import React from 'react';

const ServicePage: React.FC = () => {
  return (
    <main>
      <section id="service">
        <p>Our Services</p>
      </section>
    </main>
  );
}

export default ServicePage;
