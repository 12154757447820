import React from 'react';
// import './contactpage.css';

const ContactPage: React.FC = () => {
  return (
    <main>
      <section id="contact">
        <h2>Contact</h2>
        <p>mail: contact@data-trap.com</p>
      </section>
    </main>
  );
}

export default ContactPage;
