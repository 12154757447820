import React from 'react';
import './footer.css';

const Footer: React.FC = () => {
  return (
    <footer>
      <p>&copy; 2024 DATA-TRAP</p>
    </footer>
  );
}

export default Footer;
