import React from 'react';
import './homepage.css';

const HomePage: React.FC = () => {
  return (
    <main>
      <section id="about">
        <h2>About Us</h2>
        <p>XXXXX</p>
      </section>
      <section id="services">
        <h2>Services</h2>
        <ul>
          <li>Consulting</li>
          <li>Strategy Development</li>
        </ul>
      </section>
    </main>
  );
}

export default HomePage;
